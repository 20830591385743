import { graphql } from 'gatsby';
import React from 'react';

import Layout, {
    bgImageDefault,
    PageHeaderC,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { EnhancedLink } from '../components/link';
import { SecondaryButton } from '../components/buttons';
import { removeAllCookies } from '../components/cookiebanner';
import Seo from '../components/seo';

function Privacy({ data, location }) {
    const { t } = useTranslation();

    return (
        <Layout
            location={location}
            headerContent={
                <PageHeaderC
                    title={<Trans i18nKey="title">Datenschutz</Trans>}
                    subtitle={
                        <Trans i18nKey="subtitle">
                            Wir legen großen Wert auf den
                            Schutz&nbsp;Ihrer&nbsp;Daten
                        </Trans>
                    }
                />
            }
            bgImage={bgImageDefault.default}
        >
            <Seo title={t('title', 'Datenschutz')} />
            <PageSection isDark>
                <PageText>
                    <Trans i18nKey="cookiesReset">
                        Um Ihre Cookies zu verwalten klicken Sie bitte hier um
                        Ihre Einstellungen zurückzusetzen:
                    </Trans>
                </PageText>
                <SecondaryButton
                    onClick={() => {
                        removeAllCookies();
                        window.location.reload();
                    }}
                >
                    <Trans i18nKey="cookiesResetAction">
                        Cookies zurückzusetzen
                    </Trans>
                </SecondaryButton>
            </PageSection>
            <PageSection className="my-1">
                <PageText>
                    <Trans i18nKey="status">
                        <b>Stand</b>: Februar 2023
                    </Trans>
                </PageText>

                <PageText className="mb-0" type="highlighted">
                    <Trans i18nKey="representative">
                        Verantwortlicher gem. Art 4 Z 7 DSGVO:
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="topDescription1">condignum GmbH</Trans>
                    <br />
                    <Trans i18nKey="topDescription2">
                        Prinz Eugen Straße 70/2/1.4a-4b
                    </Trans>
                    <br />
                    <Trans i18nKey="topDescription1">
                        1040 Wien, Österreich
                    </Trans>
                    <br />
                    office@condignum.com
                    <br />
                    <Trans i18nKey="topDescription4">
                        Es ist kein Datenschutzbeauftragter bestellt, da dies
                        gesetzlich nicht notwendig ist.
                    </Trans>
                </PageText>

                <SectionTitle>
                    <Trans i18nKey="general_title">Allgemeine Hinweise</Trans>
                </SectionTitle>
                <PageText>
                    <Trans i18nKey="general_p1">
                        Wir legen großen Wert auf den Schutz Ihrer persönlichen
                        Daten. Bei der Verarbeitung halten wir uns an die
                        Bestimmungen des österreichischen Datenschutzgesetzes
                        (DSG), der EU-Datenschutz-Grundverordnung (DSGVO) sowie
                        an das Telekommunikationsgesetz 2003 (TKG 2003). Um die
                        Sicherheit der verarbeiteten Daten zu gewährleisten und
                        sicherzustellen, dass diese ordnungsgemäß verarbeitet
                        und nicht für Unbefugte zugänglich gemacht werden,
                        werden entsprechende Maßnahmen zur Datensicherheit
                        getroffen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="general_p2">
                        Diese Datenschutzerklärung klärt Sie über die Art, den
                        Umfang und die Zwecke der Erhebung und Verwendung Ihrer
                        personenbezogener Daten durch condignum im Zusammenhang
                        mit Ihrem Besuch und Ihrer Nutzung unserer Website
                        www.condignum.com (&#8222;Website&ldquo;) auf.
                    </Trans>
                </PageText>

                <SectionTitle>
                    <Trans i18nKey="data_title">
                        Datenerfassung, Speicherung und Verarbeitung
                    </Trans>
                </SectionTitle>
                <Heading>
                    <Trans i18nKey="data_log_title">Server-Log-Dateien</Trans>
                </Heading>
                <PageText>
                    <Trans i18nKey="data_log_p1">
                        Im Zuge Ihrer Nutzung der Website erheben, sammeln und
                        speichern wir folgende Daten:
                    </Trans>
                </PageText>

                <Ul>
                    <li>
                        <Trans i18nKey="data_log_l1">
                            IP Adresse und IP Standort von dem Gerät von welchem
                            aus zugegriffen wird
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="data_log_l2">
                            Referrer URL (die Adresse der zuvor besuchten
                            Internetseite)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="data_log_l3">
                            Anzahl, Dauer und Zeit der Aufrufe (Ihre Interaktion
                            mit der Website)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="data_log_l4">
                            übertragene Datenmenge
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="data_log_l5">
                            Browser und Browserversion
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="data_log_l6">Betriebssystem</Trans>
                    </li>
                </Ul>
                <PageText className="mt-4">
                    <Trans i18nKey="data_log_p2">
                        in sogenannten Logdateien (Zugriffsdaten) automatisiert
                        mithilfe eines Website-Analysetools. Die erhobenen Daten
                        werden dabei lediglich in aggregierter und
                        anonymisierter Form ausgewertet und sind nicht
                        individuell zuordenbar.
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="data_cookies_title">Cookies</Trans>
                </Heading>
                <PageText>
                    <Trans i18nKey="data_cookies_p1">
                        Unsere Website verwendet so genannte Cookies. Dabei
                        handelt es sich um kleine Textdateien, die mit Hilfe des
                        Browsers auf Ihrem Endgerät abgelegt werden. Sie richten
                        keinen Schaden an. Wir nutzen Cookies dazu, unser
                        Angebot benutzerfreundlich zu gestalten. Einige Cookies
                        bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
                        löschen. Sie ermöglichen es uns, Ihren Browser beim
                        nächsten Besuch wieder zuerkennen. Wenn Sie dies nicht
                        wünschen, so können Sie Ihren Browser so einrichten,
                        dass er Sie über das Setzen von Cookies informiert und
                        Sie dies nur im Einzelfall erlauben. Bei der
                        Deaktivierung von Cookies kann die Funktionalität
                        unserer Website eingeschränkt sein.
                    </Trans>
                </PageText>

                <PageText className="mb-0" type="highlighted">
                    <Trans i18nKey="data_cookies_p2_heading">
                        Verarbeitung von Zugriffsdaten
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="data_cookies_p2">
                        Die von Ihnen erhobenen Zugriffsdaten werden lediglich
                        für statistische Auswertungen und in aggregierter Form
                        zum Zweck des Betriebs, der Sicherheit und der
                        Optimierung der Website verwendet (überwiegend
                        berechtigte Interessen).
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="data_application_title">Bewerbung</Trans>
                </Heading>
                <PageText>
                    <Trans i18nKey="data_application_p1">
                        Wenn sie uns Ihre Bewerbungen zukommen lassen, werden
                        Ihre Angaben inklusive der von Ihnen dort angegebenen
                        Kontaktdaten, sowie ihre Bewerbungsunterlagen, zwecks
                        Bearbeitung der Bewerbung bei uns gespeichert. Diese
                        Daten geben wir nicht ohne Ihrer Einwilligung weiter.
                        Spätestens 6 Monate nach Besetzung der Stelle werden
                        ihre angegebenen und übermittelten Daten gelöscht.
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="data_contact_title">Kontaktaufnahme</Trans>
                </Heading>
                <PageText>
                    <Trans i18nKey="data_contact_p1">
                        Zum Zweck der Beantwortung Ihrer Anfrage, verarbeiten
                        wir Ihre angegebenen personenbezogenen Daten. Die
                        Rechtsgrundlage beruht auf Art. 6 Abs 1 lit b DSGVO
                        (&#8230; &#8222;zur Durchführung vorvertraglicher
                        Maßnahmen&ldquo;). Aufgrund Ihrer Anfrage ziehen wir den
                        Rückschluss, dass Sie durch die Kontaktaufnahme an
                        Leistungen von uns interessiert sind oder sich bereits
                        in einem bestehenden Kundenverhältnis befinden. Die
                        personenbezogenen Daten werden auf einen Webserver eines
                        von uns in Anspruch genommenen IT-Serviceproviders
                        gespeichert und in Folge von uns intern verarbeitet. Der
                        herangezogene Auftragsverarbeiter wird vertraglich dazu
                        verpflichtet, Ihre Daten nur im Umfang der
                        Leistungserbringung zu verarbeiten und diese vertraulich
                        zu behandeln. Es erfolgt darüber hinaus keine Weitergabe
                        an sonstige Dritte Parteien, noch an Drittländer oder
                        internationale Organisationen.
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="data_customer_title">Kundenbeziehung</Trans>
                </Heading>
                <PageText>
                    <Trans i18nKey="data_customer_p1">
                        Wir verarbeiten im Rahmen der Kundenverwaltung die von
                        Ihnen übermittelten Daten zum Zweck der
                        Vertragsabwicklung gem. Art 6 Abs 1 lit b DSGVO (…
                        „notwendig zur Vertragserfüllung“). Die von Ihnen
                        bereitgestellten personenbezogenen Daten sind zur
                        Vertragserfüllung erforderlich. Wir möchten ausdrücklich
                        darauf hinweisen, dass innerhalb der Condignum GmbH nur
                        diejenigen Mitarbeiterinnen und Mitarbeiter Ihre Daten
                        erhalten, die diese zur Erfüllung der vertraglichen,
                        gesetzlichen und aufsichtsrechtlichen Pflichten sowie
                        zur Wahrung berechtigter Interessen benötigen. Eine
                        Datenübermittlung erfolgt nach Ihrer Beauftragung
                        anlassbezogen an unseren Steuerberater, Versicherungen,
                        Banken, Inkassounternehmen, Behörden und in bestimmten
                        Anlassfällen an Rechtsvertreter und Gerichte. Darüber
                        hinaus erhalten von uns beauftragte Auftragsverarbeiter
                        (beispielsweise IT-Dienstleister) Ihre Daten, sofern sie
                        diese zweckentsprechend für die Auftragserfüllung
                        benötigen. Die herangezogenen Auftragsverarbeiter werden
                        vertraglich dazu verpflichtet, Ihre Daten nur im Umfang
                        der Leistungserbringung zu verarbeiten und diese
                        vertraulich zu behandeln.
                    </Trans>
                </PageText>

                <SectionTitle>
                    <Trans i18nKey="security_title">Datensicherheit</Trans>
                </SectionTitle>
                <PageText>
                    <Trans i18nKey="security_p1">
                        Wir setzen technische und organisatorische
                        Sicherheitsmaßnahmen ein, um die von Besuchern
                        gespeicherten Daten gegen zufällige oder vorsätzliche
                        Manipulation, Verlust, Zerstörung oder gegen den Zugriff
                        unberechtigter Personen zu schützen. Die Übertragung von
                        Daten erfolgt für Webseite ausschließlich verschlüsselt
                        durch den Einsatz von TLS (Transport Layer Security).
                        Sie erkennen die verschlüsselte Verbindung am
                        Schlosssymbol links oben im Browser und der Verwendung
                        des Schemas “https” als Teil der Internetadresse.
                    </Trans>
                    <br />
                    <Trans i18nKey="security_p2">
                        Alle Daten werden auf Servern unserer Dienstleister, mit
                        denen Verträge zur Auftragsverarbeitung nach § 28 DSGVO
                        mit entsprechender Prüfung abgeschlossen wurden,
                        gespeichert.
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="security_saving_title">
                        Datensparsamkeit
                    </Trans>
                </Heading>
                <PageText>
                    <Trans i18nKey="security_saving_p1">
                        Wir speichern Ihre Daten nur für den Zeitraum der
                        Zweckerfüllung, darüber hinaus werden nur die unbedingt
                        notwendigen Daten aufgrund der anwendbaren gesetzlichen
                        Bestimmungen bzw Aufbewahrungspflichten (UGB, ABGB etc)
                        gespeichert. Die Erhebung Ihrer Daten ist notwendig, um
                        Ihre Anfrage bearbeiten zu können. Sie stellen uns diese
                        freiwillig bereit. Von uns werden keinerlei
                        automatisierten Entscheidungsfindungen oder Profilings
                        bei dieser Verarbeitung Ihrer Daten eingesetzt.
                    </Trans>
                </PageText>

                <SectionTitle>
                    <Trans i18nKey="passing_title">Weitergabe von Daten</Trans>
                </SectionTitle>
                <PageText>
                    <Trans i18nKey="passing_p1">
                        Wir werden Ihre auf Basis Ihrer Nutzung der Website
                        erhobenen personenbezogenen Daten nicht an Dritte
                        weitergeben, es sei denn, es ist zur Erfüllung unserer
                        Pflichten erforderlich oder gesetzlich/behördlich
                        verpflichtend.
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="passing_rights_title">Ihre Rechte</Trans>
                </Heading>
                <PageText>
                    <Trans i18nKey="passing_rights_p1">
                        Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten
                        gemäß DSGVO und dem österreichischen Datenschutzgesetz
                        (DSG) folgende Rechte zu:
                    </Trans>
                </PageText>

                <Ul>
                    <li>
                        <Trans i18nKey="passing_rights_l1">
                            Recht auf Auskunft (Art. 15 DSGVO)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="passing_rights_l2">
                            Recht auf Berichtigung (Art. 16 DSGVO)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="passing_rights_l3">
                            Recht auf Löschung (“Recht auf Vergessenwerden”,
                            Art. 17 DSGVO)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="passing_rights_l4">
                            Recht auf Einschränkung der Verarbeitung (Art. 18
                            DSGVO)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="passing_rights_l5">
                            Recht auf Datenübertragbarkeit (Art. 20 DSGVO)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="passing_rights_l6">
                            Widerspruchsrecht (Art. 21 DSGVO)
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="passing_rights_l7">
                            Recht, nicht einer ausschließlich automatisierten
                            Entscheidung unterworfen zu sein (Art. 22 DSGVO)
                        </Trans>
                    </li>
                </Ul>

                <PageText className={'mt-6 mb-0'}>
                    <Trans i18nKey="passing_rights_p2">
                        Für die Geltendmachung Ihrer Rechte kontaktieren Sie uns
                        bitte wie folgt:
                    </Trans>
                </PageText>
                <Ul>
                    <li>
                        <Trans i18nKey="passing_rights_l8">
                            per E-Mail unter office@condignum.com oder
                        </Trans>
                    </li>
                    <li>
                        <Trans i18nKey="passing_rights_l9">
                            per Post an unsere Adresse
                        </Trans>
                    </li>
                </Ul>
                <PageText className={'mt-6'}>
                    <Trans i18nKey="passing_rights_p3">
                        Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
                        personenbezogenen Daten nicht im Sinne der
                        Datenschutz-Grundverordnung erfolgt, haben Sie das
                        Recht, sich bei der zuständigen Aufsichtsbehörde (DSB)
                        zu beschweren (gem. Art 14 Abs 2 lit d DSGVO). Die
                        österreichische Datenschutzbehörde ist unter
                        https://www.dsb.gv.at/ zu finden.
                    </Trans>
                </PageText>

                <SectionTitle>
                    <Trans i18nKey="tools_title">Plugins und Tools</Trans>
                </SectionTitle>

                <Heading>
                    <Trans i18nKey="tools_google_title">Google Analytics</Trans>
                </Heading>
                <PageText className={'mt-6'}>
                    <Trans i18nKey="tools_google_p1">
                        Unsere Webseite verwendet Google Analytics der Firma
                        Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
                        94043, USA) zur Auswertung von Besucherinformationen zur
                        Verbesserung unserer Services. Eine Verarbeitung der
                        Daten außerhalb der europäischen Union erfolgt auf Basis
                        der von der EU-Kommission bereitgestellten{' '}
                        <EnhancedLink
                            to="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                            className="link"
                        >
                            Standardvertragsklauseln{' '}
                        </EnhancedLink>
                        (Standard Contractual Clauses). Nähere Informationen
                        dazu finden Sie unter{' '}
                        <EnhancedLink
                            to="https://business.safety.google/intl/de/adsprocessorterms/"
                            className="link"
                        >
                            Datenverarbeitungsbedingungen für Google Ads
                            (safety.google)
                        </EnhancedLink>
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="tools_google_title">LinkedIn Insight</Trans>
                </Heading>
                <PageText className={'mt-6'}>
                    <Trans i18nKey="tools_google_p1">
                        Unsere Webseite verwendet LinkedIn Insight der Firma
                        LinkedIn Corporation (2029 Stierlin Court, Mountain View, CA 
                        94043, USA) zur Auswertung von Besucherinformationen zur
                        Verbesserung unserer Services. Eine Verarbeitung der
                        Daten außerhalb der europäischen Union erfolgt auf Basis
                        der von der EU-Kommission bereitgestellten{' '}
                        <EnhancedLink
                            to="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                            className="link"
                        >
                            Standardvertragsklauseln{' '}
                        </EnhancedLink>
                        (Standard Contractual Clauses). Nähere Informationen
                        dazu finden Sie in der{' '}
                        <EnhancedLink
                            to="https://de.linkedin.com/legal/privacy-policy"
                            className="link"
                        >
                            LinkedIn Datenschutzrichtlinie.
                        </EnhancedLink>{' '}
                        Für Nutzer in der EU, dem EWR, der Schweiz und dem Vereinigten 
                        Königreich gilt zusätzlich der{' '}
                        <EnhancedLink
                            to="https://de.linkedin.com/legal/privacy/eu?"
                            className="link"
                        >
                            LinkedIn regionale europäische Datenschutzhinweis.
                        </EnhancedLink>
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="tools_hcaptcha_title">hCaptcha</Trans>
                </Heading>
                <PageText className={'mt-6'}>
                    <Trans i18nKey="tools_hcaptcha_p1">
                        Unsere Webseite verwendet hCaptcha der Firma Intuition
                        Machines, Inc. ("IMI") (San Francisco, CA 941110, USA)
                        als Anti-Spam-Maßnahme. Informationen über die durch
                        hCaptcha verarbeiteten Daten können Sie in der
                        <EnhancedLink
                            to="https://www.hcaptcha.com/privacy"
                            className="link"
                        >
                            {' '}
                            hCaptcha - Privacy Policy{' '}
                        </EnhancedLink>
                        nachlesen. Eine Verarbeitung der Daten außerhalb der
                        europäischen Union erfolgt auf Basis der von der
                        EU-Kommission bereitgestellten
                        <EnhancedLink
                            to="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                            className="link"
                        >
                            {' '}
                            Standardvertragsklauseln
                        </EnhancedLink>{' '}
                        (Standard Contractual Clauses). Nähere Informationen
                        dazu finden Sie unter{' '}
                        <EnhancedLink
                            to="https://newassets.hcaptcha.com/dpa/IMI.DPA.9.23.21.New.SCCs.pdf"
                            className="link"
                        >
                            Data Processing Addendum.
                        </EnhancedLink>
                    </Trans>
                </PageText>

                <Heading>
                    <Trans i18nKey="tools_sentry_title">Sentry IO</Trans>
                </Heading>
                <PageText className={'mt-6'}>
                    <Trans i18nKey="tools_sentry_p1">
                        Unsere Plattform verwendet Sentry der Firma Sentry Inc.
                        (45 Fremont Street, 8th Floor, San Francisco, CA 94105,
                        USA) zur Analyse und Überwachung der Stabilität unserer
                        Webanwendung. Informationen über die durch Sentry
                        verarbeiteten Daten können Sie unter{' '}
                        <EnhancedLink
                            to="https://sentry.io/privacy/"
                            className="link"
                        >
                            Privacy Policy
                        </EnhancedLink>{' '}
                        nachlesen. Eine Verarbeitung der Daten außerhalb der
                        europäischen Union erfolgt auf Basis der von der
                        EU-Kommission bereitgestellten{' '}
                        <EnhancedLink
                            to="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                            className="link"
                        >
                            Standardvertragsklauseln
                        </EnhancedLink>{' '}
                        (Standard Contractual Clauses). Nähere Informationen
                        dazu finden Sie unter{' '}
                        <EnhancedLink
                            to="https://sentry.io/legal/dpa/"
                            className="link"
                        >
                            Data Processing Addendum.
                        </EnhancedLink>
                    </Trans>
                </PageText>

                <SectionTitle>
                    <Trans i18nKey="final_title">Schlussbestimmungen</Trans>
                </SectionTitle>
                <PageText>
                    <Trans i18nKey="final_p1">
                        Im Zuge der laufenden Weiterentwicklung des
                        Internetauftritts wird condignum diese
                        Datenschutzerklärung weiterhin anpassen. Condignum wird
                        Änderungen auf unserer Website rechtzeitig bekanntgeben.
                        Deshalb sollten Sie regelmäßig auf diese Erklärung
                        zugreifen und sich über die aktuelle Version
                        informieren.
                    </Trans>
                </PageText>
                <SectionTitle>
                    <Trans i18nKey="disclaimer_title">Haftungsausschluss</Trans>
                </SectionTitle>
                <PageText>
                    <Trans i18nKey="disclaimer_p1">
                        Die condignum Website und die darin enthaltenen Beiträge
                        wurden sorgfältig ausgearbeitet und dienen lediglich
                        allgemeinen Informationszwecken. Sie können jedoch im
                        Einzelfall individuelle rechtliche Beratung nicht
                        ersetzen und stellen keine Rechtsauskunft dar. Wir
                        übernehmen daher keine Haftung für die Richtigkeit,
                        Aktualität und Fehlerfreiheit der angebotenen
                        Informationen. Weiters übernimmt condignum auch keine
                        Haftung für etwaige Links zu externen Webseiten, die von
                        Dritten betrieben werden.
                    </Trans>
                </PageText>
            </PageSection>
        </Layout>
    );
}

function Heading({ children }) {
    return (
        <PageSubtitle className="mt-8 mb-4 underline" hasMargin={false}>
            {children}
        </PageSubtitle>
    );
}

function SectionTitle({ children }) {
    return <PageTitle className={'mt-12 mb-4'}>{children}</PageTitle>;
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["privacy"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default Privacy;
